<template>
  <b-modal class="bmodal" size="lg" hide-footer id="addDonationSummaryModal" ref="modalAddDonationSummary"
    :title="i18n['add-summary'].tcTransactionSummary" :cancel-title="i18n['add-summary'].tcCancel"
    :no-close-on-backdrop="noCloseOnBackdrop">
    <div class="formdiv">
      <b-form>
        <b-row class="srow" v-for="(item, idx) in donationsSummary" :key="idx">
          <b-col sm="7">{{ translations[item.category] }}</b-col>
          <b-col sm="5" class="aright">{{ item.amount | toCurrency }}</b-col>
        </b-row>
        <b-row class="totrow">
          <b-col sm="7" class="bold">{{ i18n['add-summary'].tcTotal }}</b-col>
          <b-col sm="5" class="bold tot">{{ amountTally | toCurrency }}</b-col>
        </b-row>
      </b-form>
    </div>
    <b-row>
      <div class="xbuttons">
        <b-button variant="primary" class="btn btn-primary float-left" @click="handleClickCancel">{{
                  i18n['add-summary'].tcCancel
                  }}</b-button>
        <b-button variant="secondary" class="btn btn-secondary float-right mr20" @click="handleSaveClick($event)">{{
                  i18n['add-summary'].tcProcess
                  }}</b-button>
      </div>
    </b-row>
    <b-row>
      <div style="color: red; text-align: center; width:100%;">{{i18n['add-summary'].tcProcessTimeNotice}}</div>
    </b-row>
  </b-modal>
</template>
<script>
/* eslint-disable */
import { mapGetters, mapActions } from 'vuex'
import { numericMixin } from '@/mixins/numericMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-donation-summary',
  mixins: [numericMixin, translationMixin],
  props: {
    translations: {
      type:Object
    },
    i18n: {
      type: Object,
      default: () => {
        return {
          'add-summary': {
            tcCancel: 'Cancel',
            tcTotal: 'Total',
            tcProcess: 'Process',
            tcProcessTimeNotice: '',
            tcTransactionSummary: 'Transaction Summary'
          },
        }
      },
    },
  },
  data() {
    return {
      noCloseOnBackdrop: true
    }
  },
  computed: {
    ...mapGetters({
      currentCamp: 'campModule/currentCamp',
      donationsSummary: 'donationModule/donationsSummary',
    }),
    amountTally() {
      let transactionTotal = 0.0
      this.donationsSummary.forEach((element) => {
        transactionTotal += parseFloat(element.amount)
      })
      return transactionTotal.toFixed(2)
    },
  },
  methods: {
    ...mapActions({
    setLoadingStatus: 'menu/setLoadingStatus',
    }),
    closeModal() {
      this.$refs.modalAddDonationSummary.hide()
    },
    handleClickCancel() {
      this.closeModal()
    },
    async handleSaveClick() {
      try {
        await this.setLoadingStatus(true)
        this.closeModal()
        this.$emit('success')
      } catch (e) {
        console.error(e)
      }
    },
  },
}
</script>
<style scoped>
i {
  font-size: 12px;
}

.row {
  line-height: 36px;
}

.mr20 {
  margin-right: 20px;
}

.srow {
  font-size: 15px;
}

.totrow {
  background-color: #003946;
  color: #ffffff;
  margin-bottom: 20px;
}

.bold {
  font-weight: 700;
}

.tot {
  font-size: 18px;
  text-align: right;
}

.aright {
  text-align: right;
}

.activity-input {
  margin-top: 2px;
  text-align: right;
}

.xbuttons {
  margin: 30px 20px 20px 20px;
  width: 100%;
}

.btn.btn-secondary {
  background-color: #fbc301;
}

.btn.btn-primary {
  background-color: #003946;
}

.btn.btn-primary:hover {
  background-color: #003946;
}

.btn.btn-secondary:hover {
  background-color: #fbc301;
  -color: #003946;
}

.modal-header {
  height: 63px;
}
</style>